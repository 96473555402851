<template>
   <div class="container">

      <div class="commonList" v-show="dataList.length>0">
         <table>
               <thead>
                  <tr>
                     <td>ID</td>
                     <td>用户ID</td>
                     <td>标题</td>
                     <td>信息内容</td>
                     <td>上报时间</td>
                  </tr>
               </thead>
               <tbody>
                  <tr v-for="(item,index) in dataList" :key="index">
                     <td>{{item.id}}</td>
                     <td><a :href="'https://www.sidoc.cn/user/' + item.user_id" target="_blank">{{item.user_id}}</a></td>
                     <td>{{item.title}}</td>
                     <td><el-button @click="dialogVisible=true;selectedItem=item" type="text" style='padding:0px;'>查看详情</el-button></td>
                     <td>
                         <span style="min-width:100px;display: inline-block;">{{item.create_time |getDiffToToday}}</span>
                         <span style="color:gray;">{{item.create_time}}</span>
                     </td>
                  </tr>
               </tbody>
         </table>

         <el-pagination
            background
            @current-change="loadData"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="total"
            style="text-align: right;margin-top: 10px;">
         </el-pagination>

      </div>

      <div class="commonLoading" v-show="isLoading">正在加载...</div>

      <el-dialog :title="selectedItem.title" :visible.sync="dialogVisible" width="70%" top='20px'>
         <el-input type="textarea" :rows="30" v-model="selectedItem.content"></el-input>
      </el-dialog>

   </div>
</template>

<script>

export default {
    data(){
        return {
            dataList:[],
            pageSize:18,
            currentPage:0,
            total:0,
            
            selectedItem:{}, // 当前选中项

            isLoading:false,
            dialogVisible:false,
        }
    },
    mounted(){
        this.loadData(this.currentPage);
    },
    methods:{
        loadData(currentPage){
            this.isLoading = true;
            let that = this;

            let params = {
                "currentPage":currentPage,
                "pageSize":this.pageSize
            }
            this.$api.infoReportList(params).then(res => {
                if(res.code == 0){
                    that.dataList = res.data.list;
                    that.total = res.data.count;
                }
            }).finally(()=>{
                that.isLoading = false;
            });
        }
    }
   
}
</script>

<style scoped>

</style>